import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "pageNotFound",
  class: "center page-not-found flex-mid"
}
const _hoisted_2 = { class: "pb-40" }
const _hoisted_3 = { class: "text fs-40" }
const _hoisted_4 = { class: "fs-24 mb-40" }
const _hoisted_5 = { class: "flex-mid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "number" }, "404", -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("fourZeroFour.Looks_like_something")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("fourZeroFour.the_page_or")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "ml-auto btn btn-50 btn-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLink('ladingPage')))
        }, _toDisplayString(_ctx.$t("fourZeroFour.Our_Website")), 1),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mx-16" }, null, -1)),
        _createElementVNode("div", {
          class: "mr-auto btn btn-50 btn-primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
        }, " MyColture App ")
      ])
    ])
  ]))
}